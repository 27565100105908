import policy from '../policy/en'
import terms from '../terms/en'

export default {
  app: {
    title: 'Robux Pier',
    description: 'Honesty. Convenience. Benefit. The best Robux store with a long history. Have fun playing with robuxpier.',
    titlePostfix: {
      methods: 'Select method',
      transfer: 'Transfer method',
      logpass: 'LogPass method',
      premium: 'Premium LOG+PASS method',
      payment: 'Refill'
    }
  },
  navigation: {
    title: 'NAVIGATION',
    home: 'HOME',
    logoText: 'Working since 2018',
    buyRobux: 'BUY ROBUX',
    lk: 'PERSONAL ACCOUNT',
    vk: 'VK Group',
    discord: 'Discord',
    telegram: 'Telegram',
    instagram: 'Instagram',
    tiktok: 'Tik tok',
    dropdown: {
      history: 'History',
      changePassword: 'Change password',
      refill: 'Top up',
      logout: 'Exit',
      copyId: 'Copy ID'
    }
  },
  general: {
    warning: 'Attention!',
    error: 'Error',
    gotIt: 'Understood',
    confirm: 'CONFIRMATION',
    or: 'or',
    link: 'link',
    transfer: 'transfer',
    policy: 'PRIVACY POLICY',
    terms: 'USER AGREEMENT',

    errors: {
      internal: 'Internal error, please try again later',
      server: 'Server error'
    }
  },
  actions: {
    continue: 'Continue',
    submit: 'CONFIRM',
    buyRobux: 'BUY ROBUX',
    openDiscord: 'GO TO DISCORD',
    openInstruction: 'GO TO THE INSTRUCTIONS',
    createAccount: 'Create an account',
    login: 'LOG IN TO ACCOUNT',
    loginVK: 'Log in using VK',
    signin: 'Log in',
    enterPassword: 'Enter it',
    skip: 'Skip',
    buy: 'Purchase',
    buyOrder: 'Buy',
    tryAgain: 'Repeat again',

    create: 'Create',
    ready: 'Ready',
    edit: 'Edit',
    apply: 'Apply',
    participate: 'Participate',
    try: 'Try',
    watch: 'Watch',
    promocode: {
      default: 'The promo code has not been applied',
      success: 'Promo code applied',
      error: 'The promo code could not be applied',
      minError: 'The minimum amount to activate the promo code {min} R$',
      maxError: 'The maximum amount to activate a promo code {max} R$',
      isNoPromo: 'This promo code does not exist!',
      promoExpired: 'The promo code has expired!',
      notAuth: 'Authorization is required!'
    }
  },
  payment: {
    refill: 'Replenishment of the balance',
    total: 'Total',
    toPay: 'To be paid',
    pay: 'PAY',
    makeOrder: 'Pay for the order',
    otherPaymentMethods: 'Other payment methods',
    enterSum: 'Enter the deposit amount',
    commission: 'Commission',
    notEnough: "You don't have enough funds to pay!",
    problems: 'In case of problems, write to our {support}',
    support: 'support service',
    selectPaymentMethod: 'CHOOSE A PAYMENT METHOD'
  },
  order: {
    status: 'ORDER STATUS',
    wait: 'PLEASE WAIT FOR the order to be completed',
    waitTime: 'REMAINS',
    code: 'The code must consist of 6 digits',
    mailCode: 'Enter the code from the mail',
    makeNewOrder: 'Make another order',
    table: {
      nickname: 'GAME NICKNAME',
      count: 'QUANTITY R$',
      total: 'TOTAL, RUB'
    },
    descriptions: {
      wait: 'Wait for the order to be completed',
      notEnoughFunds: "Error. The site doesn't have enough funds!",
      systemError: 'System error!',
      checkVip: 'Check the settings VIP ROBLOX!',
      connectionError: 'Communication error with ROBLOX!',
      success: 'The order has been successfully completed!',
      checkGamepass: 'Check the Gamepass settings!'
    }
  },
  auth: {
    authorization: 'Authorization',
    registration: 'Registration',
    recovery: 'RECOVERY',
    change: 'CHANGE PASSWORD',

    inputs: {
      email: 'Enter E-mail',
      login: 'Enter login',
      loginOrEmail: 'Enter username or E-mail',
      currentPassword: 'Current password',
      newPassword: 'New password',
      repeatNewPassword: 'Repeat the new password',
      password: 'Enter password',
      passwordSubmit: 'Confirm password'
    },

    errors: {
      email: 'Invalid E-mail!',
      incorrectPassword: 'The password is incorrect!',
      empty: 'The field is empty',
      nicknameIsTooSmall: 'You must specify a name consisting of at least 4 characters',
      inactiveEmail: 'Email is invalid',
      passwordIsTooSmall: 'Your password must consist of at least 6 characters',
      passwordNotMatch: "Passwords don't match",
      oneUppercase: 'Your password must have at least one capital letter',
      userExist: 'A user with such data already exists',
      checkEmail: 'Make sure your email/username is correct',
      checkPassword: 'Make sure your password is correct',
      changePasswordIncorrect: 'The password must be from 6 to 18 characters and consist of the letters “A-z”!'
    },

    welcome: {
      title: 'WELCOME',
      content: 'REGISTER ON OUR WEBSITE AND START BUYING ROBUX SECURELY, QUICKLY AND SAFELY'
    },

    forget: 'Forgot your password?',
    remember: 'Did you remember the password?',
    agreement: 'I agree with {policy} and {agreement}',
    privacyPolicy: 'privacy policy',
    userAgreement: 'user agreement',
    haveAccount: 'Do you already have an account?',
    codeSent: 'A password recovery code has been sent to {email}!',
    enterCode: 'Enter the 6-digit code:',
    requestCode: 'Request again',

    changePasswordSuccess: {
      title: 'Great!',
      content: 'You have successfully changed your password! Be sure to remember...'
    }
  },
  slider: {
    slide1: {
      title: 'BLOGGERS TRUST US',
      content: 'OVER THE SIX YEARS OF THE SITE\'S EXISTENCE, THERE HAVE BEEN HUNDREDS OF THOUSANDS OF ORDERS. BUY SAFELY IN A TRUSTED STORE'
    },
    slide2: {
      title: 'WE HAVE THE LOWEST PRICES!',
      content: 'BUY ROBUX AND BECOME THE BEST AMONG YOUR FRIENDS. MAKE YOUR DREAM COME TRUE!'
    },
    slide3: {
      title: 'STILL IN DOUBT?',
      content: 'THOUSANDS OF SATISFIED CUSTOMERS WITH REAL REVIEWS ON OUR SOCIAL NETWORKS!'
    },
    slide4: {
      title: 'DRAWING ON <span class="active-text"> 30 000 ROBUX!</span>',
      content: 'Are you still not participating? Then someone else will win'
    }
  },
  faq: {
    title: 'КУПИТЬ РОБУКСЫ ЛЕГКО!',
    content: 'FOLLOW THREE SIMPLE STEPS',
    items: {
      item1: {
        index: '01',
        title: 'MAKE AN ORDER',
        content: 'ENTER THE NUMBER OF ROBUX AND YOUR NICKNAME ROBLOX'
      },
      item2: {
        index: '02',
        title: 'Pay',
        content: 'Pay for the order in any convenient way'
      },
      item3: {
        index: '03',
        title: 'GET ROBUX',
        content: 'Get robux to your gaming account within 5 days!'
      }
    }
  },
  help: {
    title: 'Having trouble buying robux?',
    text1: 'Our support will definitely help you with them on the server in discord or in personal Instagram messages!',
    text2: 'You can also use the instructions for'
  },
  footer: {
    privacy: 'PRIVACY POLICY',
    terms: 'USER AGREEMENT',
    contacts: 'CONTACT INFORMATION'
  },
  partnership: {
    title: 'Partnership',
    info: 'We offer profitable partnership for bloggers'
  },
  methods: {
    title: 'WHICH PURCHASE METHOD DO YOU WANT TO USE?',
    hintError: {
      title: 'Error!',
      content: 'You already have Roblox Premium!'
    },
    method: 'Method',
    fast: 'Fast',
    pass: 'Pass',
    robux: 'Robux',
    premium: 'Premium',
    transfer: 'Transfer',
    logpass: 'log+pass',
    available: 'Available',
    notAvailable: 'Out of stock'
  },
  cookie: {
    whatIsCookie: 'What is a COOKIE?',
    checkInstruction: 'READ THE COOKIE INSTRUCTIONS',
    enter: 'Enter a Cookie',
    description: {
      part1: 'Cookies themselves are not dangerous — they are ordinary text files. They cannot run processes on the computer or interact with the operating system at all. But they may try to intercept or steal them in order to track your previous online activities or log into your accounts without authorization.',
      part2: 'Usually, the information that is recorded in cookies is encrypted before sending, and the cookies themselves are transmitted via HTTPS protocol. This helps protect user data, but the site developer is responsible for implementing encryption and secure sending. Visitors can only hope that everything is set up correctly. For their part, the user can only prohibit the browser from using cookies or from time to time to clean them themselves.'
    }
  },
  // transfer: {
  //   robuxDelivery: 'Robux will be credited within 5 days (120 hours)',
  //   available: 'Available:',
  //   robuxCount: 'Number of robux',
  //   minumum: 'Minimum from {min} R$',
  //   nickname: 'Game nickname',
  //   notFound: 'Nothing found',
  //   checkPlaces: 'PLEASE CHECK THAT YOU HAVE CREATED AT LEAST 1 PLACE THAT IS PUBLIC',
  //   vipPrice: 'Set the VIP server price to {price}',
  //   changePrice: 'Change the price',
  //   orderSuccess: 'You can track the receipt of Robux to your account {here}',
  //   orderError: 'Funds have been returned to the ROBUXPIER account balance. Try again :)',
  //   here: 'here',
  //   gamepass: {
  //     checkData: 'Check the correctness of the data in Gamepass!',
  //     creating: 'CREATING',
  //     settingUp: 'SETUP',
  //     createGamepass: 'Create a Game-pass',
  //     checkInstruction: 'View the instructions and set the price <span>{price} R$</span>',
  //     checkInstructionNew: 'Take a close look at the instructions',
  //     serverError: 'Something went wrong. Try again.',
  //     setPrice: 'Set the price'
  //   },
  //
  //   errors: {
  //     insufficientBalance: 'Insufficient funds on the balance sheet',
  //     notAuthorized: 'You are not logged in',
  //     serverError: 'Error on the server',
  //     robuxNotAvailable: 'The number of robucks is not available for purchase',
  //
  //     priceNotNumber: 'The price must be a number',
  //     robuxMinimum: "You can't order less than {robux} robucks",
  //     robuxMaximun: 'The maximum purchase amount is temporarily limited to 5k R$',
  //     halfBalance: "You can't order more than half of the balance: {balance} robucks",
  //     emptyBalance: 'The site has run out of robux, waiting for replenishment',
  //     robuxMustDivide: 'R$ must be divided by {orders}. Try <span class="min">{closestMin}R$</span> or <span class="max">{closestMax}R$</span>'
  //   },
  //   selectYourPlace: 'Choose your own'
  // },
  transfer: {
    title: 'Purchase by transfer method',
    subTitle: 'Delivery time for Robux to the account is 5 days',
    charactersTitle: 'Enter your nickname',
    charactersSubTitle: 'Select your character from the list',
    placeTitle: 'Choose your place',
    paymentSubTitle: 'Please double-check all information before payment',
    robuxDelivery: 'Robux will be credited within 5 days (120 hours)',
    available: 'Available:',
    robuxCount: 'Number of Robux',
    minumum: 'Minimum of {min} R$',
    nickname: 'In-game nickname',
    notFound: 'Nothing found',
    checkPlaces: 'PLEASE CHECK THAT YOU HAVE AT LEAST 1 PLACE CREATED THAT IS PUBLIC',
    vipPrice: 'Set the price of the VIP server to {price}',
    changePrice: 'Change price',
    orderSuccess: 'You can track the delivery of Robux to your account {here}',
    orderError: 'Funds have been returned to the ROBUXPIER account balance. Please try again :)',
    here: 'here',
    promoSwitch: 'I have a promo code',
    promoInfo: 'Where to get a promo code?',
    gamepass: {
      checkData: 'Check the correctness of the data in Gamepass!',
      creating: 'CREATING',
      settingUp: 'SETTING UP',
      createGamepass: 'Create a Game-pass',
      checkInstruction: 'Check the instructions and set the price to <span class="text-color--blue">{price} R$</span>',
      checkInstructionNew: 'Carefully review the instructions',
      serverError: 'Something went wrong. Please try again.',
      setPrice: 'Set the price',
      success: 'Successful!',
      successHint: 'This Gamepass is already set up! If you want to change the amount, then please start the purchase again!'
    },
    errors: {
      insufficientBalance: 'Insufficient funds in the balance',
      notAuthorized: 'You are not authorized',
      serverError: 'Server error',
      robuxNotAvailable: 'The number of Robux is not available for purchase',
      priceNotNumber: 'The price must be a number',
      robuxMinimum: 'Cannot order less than {robux} Robux',
      robuxMaximun: 'The maximum purchase amount is temporarily limited to 5k R$',
      halfBalance: 'Cannot order more than half of the balance: {balance} Robux',
      emptyBalance: 'Robux are out of stock, please wait for replenishment',
      robuxMustDivide: 'R$ must be divisible by {orders}. Try <span class="min">{closestMin}R$</span> or <span class="max">{closestMax}R$</span>'
    },
    selectYourPlace: 'Select your'
  },
  logpass: {
    enterNickname: 'ENTER A NICKNAME',
    buyRobuxTitle: 'BUYING robux',
    codes: {
      code_request: 'Please enter the code that came to your email',
      order_awaiting: 'Wait for the order to be completed',
      code_request_awaiting: 'Expect a code request from the mail',

      password_error: 'Invalid password in ROBLOX',
      code_error: 'Invalid two-step authentication code from mail',
      cookie_error: 'Error. Invalid authorization data',
      email_timeout_error: "You didn't specify the code! Repeat the order and try to enter the code as soon as possible!",
      error: 'Error. Try again',
      success: 'Order completed successfully',
      waiting: 'Wait for the order to be completed'
    }
  },
  premium: {
    buyPremium: 'PREMIUM PURCHASE',
    alreadyExist: 'You already have Roblox Premium!',
    subscribe: {
      title: '{percent} TO THE ROBUX!',
      subtitle: 'with a Premium subscription you will get more robux '
    },
    getRbx: 'Get {rbx} to the account',
    buyWithProfit: 'BUY WITH PROFIT!',
    currencyPerMonth: '{currency} for a month',
    toPay: 'Total to be paid: ',
    premiumTime: 'Premium subscription is purchased only for {time}',
    oneMonth: '1 months without auto-renewal',
    enterPromocode: 'Enter a promo code',
    ordering: 'MAKING AN ORDER',
    mailCode: 'EXPECT A CODE REQUEST FROM THE MAIL',

    correctSum: 'Enter the correct amount',
    minRefillSum: 'The deposit amount must be at least 5 rubles'
  },
  fastpass: {
    characters: {
      inventory: 'Your inventory must be opened in your privacy settings!'
    },
    instruction: {
      plugin: 'PLUGIN',
      mobileError: 'We apologize, but it is not possible to create a plugin from a phone.\n\nPlease use a computer.',
      checkPlugin: 'Check the correctness of the data in the PLUGIN!',
      create: 'CREATION',
      edit: 'SETTINGS',
      select: 'SELECT',
      createSubtitle: 'Look at the instructions and follow all the steps indicated. Specify the price',
      editSubtitle: 'Configure your plugin. Specify the price',
      selectSubtitle: 'Select the plugin you want to use',
      actions: {
        create: 'CREATE PLUGIN',
        edit: 'CONFIGURE PLUGIN'
      }
    }
  },
  supportChat: {
    hint: 'Do you have a question?',
    title: 'PLAYER SUPPORT',
    operator: 'Your assistant',
    actions: 'Options for action',
    anket: 'Introduce yourself to contact the operator. You will definitely get answers to questions related to RobuxPier.',
    name: 'Name',
    email: 'E-mail',
    vk: 'Link to Vkontakte',
    send: 'Send',
    enterMessage: 'Enter a message...'
  },
  qiwi: {
    details: 'REPLENISHMENT DETAILS',
    passport: 'Do you have a Russian Passport?',
    noPassport: 'Since you do not have a Passport of the Russian Federation, you will not be able to pass verification in the Qiwi payment system.',
    hasPassport: 'You need to pass verification on the Qiwi website so that the commission is less.',
    noPassportCommission: 'The commission will be 7.5%',
    hasPassportCommission: 'The commission will be 3%',
    yes: 'Да',
    no: 'No'
  },
  history: {
    nickname: 'Game nickname',
    sumRobux: 'Amount, R$',
    sumRub: 'amount',
    status: 'Status',
    description: 'Description',
    timer: 'Timer',

    error: 'Error',
    wait: 'Waiting',
    success: 'Successful',
    orderCount: 'Showing {order} of {length} records',
    pendingInfo: 'Track the receipt of robux'
  },
  modals: {
    feedback: {
      title: 'Do you like our website?',
      text: 'Please write a good review about our website! It will help to please you with low prices and reliable delivery!',
      submit: 'Write a review'
    },
    social: {
      title: 'This window will appear only 1 time!',
      text: 'Promo codes, sweepstakes, memes and news are the reasons to subscribe to our social networks.'
    },
    partnership: {
      title: 'Partnership',
      text: 'If you are a blogger and collect consistently from 3000 views, write a telegram to our manager in <span class="active">Telegram</span>',
      submit: '@Arina89731'
    },
    promocode: {
      title: 'Where can I get a promo code?',
      text: 'Subscribe to our social networks, we regularly publish profitable promo codes there!'
    }
  },
  policy,
  terms
}
